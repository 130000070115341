// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {

}

.text-input {
  border-radius: .5rem;
  width: 100%;
  border: none;
  font-size: 1rem;
  opacity: .2;
}

button {
  width: 100%;
  border-radius: .5rem;
  font-size: 1rem;
  opacity: .7;
  background-color: rgba(227, 184, 139, .7);
  color: white;
  margin-top: 2rem;
  padding: .5rem;
  border: none;
}



div[class^='form-'] {
  padding: 1rem;
}

.form-header { 
  text-align: center;
}

.form-submit-button {
  width: 50%;
}

.form-header, h2 {
  white-space: nowrap;
}

.error-text {
  color: rgba(180, 0, 0, .5)
}

`, "",{"version":3,"sources":["webpack://./src/waitlist.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;EACE,oBAAoB;EACpB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,WAAW;AACb;;AAEA;EACE,WAAW;EACX,oBAAoB;EACpB,eAAe;EACf,WAAW;EACX,yCAAyC;EACzC,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,YAAY;AACd;;;;AAIA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;AACF","sourcesContent":[".form-container {\n\n}\n\n.text-input {\n  border-radius: .5rem;\n  width: 100%;\n  border: none;\n  font-size: 1rem;\n  opacity: .2;\n}\n\nbutton {\n  width: 100%;\n  border-radius: .5rem;\n  font-size: 1rem;\n  opacity: .7;\n  background-color: rgba(227, 184, 139, .7);\n  color: white;\n  margin-top: 2rem;\n  padding: .5rem;\n  border: none;\n}\n\n\n\ndiv[class^='form-'] {\n  padding: 1rem;\n}\n\n.form-header { \n  text-align: center;\n}\n\n.form-submit-button {\n  width: 50%;\n}\n\n.form-header, h2 {\n  white-space: nowrap;\n}\n\n.error-text {\n  color: rgba(180, 0, 0, .5)\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
