import { useEffect, useState } from 'react';
import './waitlist.css'
import { SubmitHandler, useForm } from 'react-hook-form';

type FormState = {
  firstName: string
  email: string
}

const Waitlist = () => {


  const { register, handleSubmit, formState: { errors } } = useForm<FormState>();

  const submitForm: SubmitHandler<FormState> = (data) => {
    console.log(data);
  }



  return (
    <form className="form-container" onSubmit={handleSubmit(submitForm)}>
      <div className='form-header'>
      <h2> hi! join our waitlist </h2>

      </div>

      <div className='form-name'>

        <label>your name: </label>
        <input
          {...register("firstName", { required: true, minLength: 2 })}
          className='text-input'
          style={ errors.firstName && { backgroundColor: "rgba(280, 0, 0, .7)" } }
          type={"text"}
        />
        <br />
      </div>


      {/* email */}
      <div className='form-email'>
        <label>your email address: </label>
        <input
          {...register("email", { required: true })}
          className='text-input'
          style={ errors.email && { backgroundColor: "rgba(280, 0, 0, .7)" } }
          type={"email"}
        />
      </div>

      <div className='form-button'>
        <button type={"submit"}> submit </button>
      </div>
    
    </form>
  )

}

export default Waitlist;